import {processHTML} from "../../lib/utils";
import {Paragraphs} from "../paragraph/paragraphs";
import React from "react";
import {NodeBasicPageProps} from "../nodes/node--basic-page";


export function NodePageOgilvyPerformancePage({ node, ...props }: NodeBasicPageProps) {
    return (
        <>
            <h1 className="fake-heading">{node.title}</h1>
            {node.body?.processed && (
                <div
                dangerouslySetInnerHTML={{ __html: processHTML(node.body?.processed) }}
                className="wysiwyg"
                />
            )}
            { node.field_paragraphs.length > 0 &&
                <Paragraphs paragraphs={node.field_paragraphs} key="paragraphs"/>
            }
        </>
    )
}
